import React from 'react';
import Link from 'next/link';
import Header from 'components/header';
import Footer from 'components/footer';

const NotFound = () => (
  <>
    <Header>
      <meta name="robots" content="noindex, nofollow" />
    </Header>
    <div className="main container my-4 p-3">
      <div className="article text-center">
        <p className="h1">
          <b>404</b>
        </p>
        <p className="h4 my-4">We are sorry, page not found.</p>
        <p className="h5 my-4">
          The page you are looking for might have been removed has its name changed or is
          temporarily unavailable.
        </p>
        <p className="h5 my-4">
          <Link href="/">
            <a>Back to Homepage</a>
          </Link>
        </p>

        <h2 className="mt-5 mb-4 h4 font-weight-bold">
          <span className="underline">You May Also Like</span>
        </h2>

        <div className="my-4">
          <iframe
            title="chaturbate shows"
            src="https://chaturbate.com/in/?track=default&tour=x1Rd&campaign=7u7sN&c=6&p=0&gender=f"
            frameBorder="0"
            className="w-100"
            style={{ height: '390px' }}
          />
        </div>
      </div>
    </div>
    <Footer />
  </>
);

export default NotFound;
